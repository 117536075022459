import { useParams } from 'react-router-dom';
import { categoriaExists, preguntasByCategoria } from '../../../services/preguntasFrecuentes'
import { Box, Grid, Typography, CircularProgress,  } from '@mui/material';
import { styled } from '@mui/material/styles'
import { PreguntasBreadcrumbs, PreguntasList } from '../components';
import { useCallback, useEffect, useState } from 'react';

export const PreguntasFrecuentesCategoria = () => {

  const TypographyTitle = styled(Typography)({
    fontFamily: 'Poppins-Bold',
    fontSize: '44px',
    color: '#00ce7c'

  })
  const { categoria } = useParams();
  const [preguntasFrecuentes, setPreguntasFrecuentes] = useState(null)
  const [categoriaData, setCategoriaData] = useState([])
  const [catExist, setCatExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [itemsBreadcrumbs, setItemsBreadcrumbs] = useState([])


  const getPreguntasByCategoria = useCallback(async () => {
    const resp = await categoriaExists(categoria);
    const idCat = resp[0].id;
    setCategoriaData(resp);
    if (resp.length > 0) {
      const preguntas = await preguntasByCategoria(idCat);
      setPreguntasFrecuentes(preguntas);
      setItemsBreadcrumbs([{ title: resp[0].name, slug: `preguntas-frecuentes/${resp[0].slug}` }]);
      setCatExist(true);
    }
    setIsLoading(false);
  }, [categoria]); // 'categoria' es la dependencia

  useEffect(() => {
    getPreguntasByCategoria();
  }, [getPreguntasByCategoria])


  if (isLoading) {
    return (
      <Box sx={{ minHeight: '50dvh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <CircularProgress color='success' />
      </Box>
    )
  }

  if (!catExist) {
    return (<Box sx={{ minHeight: '50dvh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      <TypographyTitle>Verifica la url, no se encontro información</TypographyTitle>
    </Box>)
  }
  return (
    <Grid container maxWidth='xl' sx={{margin: 'auto'}}>
      <PreguntasBreadcrumbs items={itemsBreadcrumbs}/>
      <Grid container columnSpacing={10} sx={{ marginTop: '40px', alignItems: 'center' }}>
        <Grid item xs={12}><TypographyTitle sx={{paddingX:{xs: 2 ,md: 8}, lineHeight: '1.3', fontSize: { xs: '44px', md: '40px', lg: '44px' }, textAlign: { xs: 'center', md: 'left' } }}>{categoriaData?.[0]?.name}</TypographyTitle></Grid>
      </Grid>
      <PreguntasList preguntas={preguntasFrecuentes} cantidad={8} />
      <Grid container columnSpacing={3} sx={{ marginTop: 5, marginBottom: 5 }}>

      </Grid>
    </Grid>
  )
}
