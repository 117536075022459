import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { listCategoria, listPreguntas } from '../../../services/preguntasFrecuentes'
import { Box, Grid, Typography, Link, CircularProgress } from '@mui/material';
import backgroundImg from '../../../assets/images/home/home-cambia-o-vende_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import { styled } from '@mui/material/styles'
import { PreguntasList } from '../components';


export const PreguntasFrecuentes = () => {

  const LinkButton = styled(Link)({
    display: 'inline-block',
    width: '100%',
    padding: '16px 0px',
    margin: '10px',
    backgroundColor: '#00CE7C',
    textAlign: 'center',
    color: 'white',
    fontSize: 18,
    borderRadius: 25
  });
  const TypographyTitle = styled(Typography)({
    fontFamily: 'Poppins-Bold',
    fontSize: '36px',
    color: '#00ce7c'

  })

  const [preguntasFrecuentes, setPreguntasFrecuentes] = useState(null)
  const [categoriaResp, setCaregoriaResp] = useState(null);

  const getPreguntas = async () => {
    const resp = await listPreguntas();
    setPreguntasFrecuentes(resp);
  }

  const getCategorias = async () => {
    const resp = await listCategoria();
    setCaregoriaResp(resp)
  }

  useEffect(() => {
    getPreguntas();
    getCategorias();
  }, [])

  if(!preguntasFrecuentes){
    return (
      <Box sx={{minHeight: '50dvh', justifyContent: 'center', alignItems: 'center', display:'flex' }}>
        <CircularProgress color='success'/>
      </Box>
    )
  }
  return (
    <>
      <Grid container columnSpacing={0} sx={{ marginTop: {xs:'40px', md:0}, alignItems: 'center' }}>
        <Grid item md={6} xl={5} sx={{marginLeft: 'auto', paddingX:{xs: 5 ,md: 8, lg: 8} }}><TypographyTitle sx={{ lineHeight: '1.3', textAlign: {  md: 'left' } }}>Procesos seguros y transparente con Nowy</TypographyTitle></Grid>
        <Grid item md={6}><Box component='img' src={backgroundImg} sx={{ marginLeft:'auto', display: { xs: 'none', md: 'flex' } }} /></Grid>
      </Grid>
    <Grid container maxWidth='xl' sx={{margin: 'auto'}}>

      <PreguntasList preguntas={preguntasFrecuentes} />
      <Grid container columnSpacing={3} sx={{ marginTop: 5, marginBottom: 5, paddingX: {xs:5, lg: 8}}}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.5em' }}>Consulta por servicio</Typography>
        </Grid>


        {
          categoriaResp &&  categoriaResp.map((cat, index) => (
            <Grid item key={index} xs={12} sm={4}>
              <LinkButton
                underline='none'
                component={RouterLink}
                to={`${cat?.slug}`}
                >
                {cat?.name}
              </LinkButton>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
        </>
  )
}
