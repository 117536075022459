import {
    Button,
    Skeleton,
    Chip,
    Input,
    FormHelperText,
    Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles'
import { useEffect, useRef, useState, useContext } from "react";
import ImageGallery from 'react-image-gallery';
import { useParams } from "react-router-dom";
import defaultImg from '../../../assets/images/car-default-detail.jpg';
import searchService from '../../../services/searchService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { SocialIcon } from 'react-social-icons';
import './CarDetail.scss';
import { handleProduct } from "../../../services/stripeService";
import LoginModal from "./components/LoginModal";
import { AuthContext } from "../../context/AuthContext";

// stripe token
import { loadStripe } from '@stripe/stripe-js'


// services
import BuyService from "../../../services/buyService";

// svg components
import Certificate from '../../svg/Certificate'

// import ReactPixel from 'react-facebook-pixel';

// resources
import zoom from '../../../assets/images/zoom/zoom.png'
import { VideoCall } from "@mui/icons-material";
import { LoadingSkeletonCarDetail, SpecsCarDetail } from "./components";

// private const
const zoomUrl = 'https://us06web.zoom.us/j/84291532904?pwd=VGtyaytkZWZZS3hNWklDakZqWmwwdz09'








const ButtonsCarDetail = styled(Button)({

    borderColor: '#00CE7C',
    borderRadius: '20px',
    color: '#00CE7C',
    minWidth: { sm: 190, md: 209 },
    "&:hover": {
        borderColor: '#000',
        color: '#000',
    }
})



// Car Detail
function CarDetail(props) {
    const numberFormat = (number) => number.toLocaleString('es-MX');

    // state
    const [loading, setLoading] = useState(true)
    const [car, setCar] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [setPrePaymentForm] = useState(false)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [tel, setTel] = useState('')
    const [telError, setTelError] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [openModal, setOpenModal] = useState(false);
    const [datalogin, setDatalogin] = useState('')
    const { isAuthenticated } = useContext(AuthContext);


    const dataFetchedRef = useRef(false)
    const { carId } = useParams()
    const [gallery, setGallery] = useState([{ original: defaultImg, thumbnail: defaultImg }])

    console.log(isAuthenticated);

    useEffect(() => {
        const getCar = (id) => {
            searchService.getCar(id).then(carData => {
                setLoading(false);
                setCar(carData);

                if (!!carData.media.length) {
                    setGallery(
                        carData.media
                            .filter(img => img.media_file_type === 'IMAGE')
                            .map(img => {
                                return { original: `${process.env.REACT_APP_MEDIA_HOST}${img.media_file_url}`, thumbnail: `${process.env.REACT_APP_MEDIA_HOST}${img.media_file_url}` };
                            })
                    );
                }
            });
        }

        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getCar(carId);
    }, [carId]);

    const sendWhatsapp = () => {
        let error = false

        if (name.trim() === '') {
            setNameError('Ingrese nombre o razón social')
            error = true
        }

        if (tel.trim() === '') {
            setTelError('Ingrese un teléfono de contacto')
            error = true
        }

        if (email.trim() === '') {
            setEmailError('Ingrese un correo electrónico de contacto')
            error = true
        }

        if (error) {
            return
        }
        console.log(nameError);

        let message = `Me interesa este Auto ${window.location.href}`
        message += '\n\nMis datos de contacto\n'
        message += `Nombre/Razón Social: ${name}\n`
        message += `Teléfono de Contacto: ${tel}\n`
        message += `Correo Electrónico: ${email}`
        message = encodeURIComponent(message)

        const whatsappNumber = '526693830568'
        const whatsappUrl = `whatsapp://send?phone=${whatsappNumber}&text=${message}`

        return whatsappUrl
        // window.open(whatsappUrl)
    }

    const sendBuyRequest = () => {
        BuyService.send({
            name,
            email,
            phone: tel,
            inventory_id: carId,
        })
    }

    const openZoom = () => {
        window.open(zoomUrl)
    }

    const sendpayment = async () => {
        const stripePromise = loadStripe("pk_test_51NBifqK5bcgroJGATf2tu5YLZwC4DLksrPpLP2ntMvUPKS0vQedSZ6aDZOu1hZ9jhz6yrd0vkkJiSBp0nXW0iamy00CpUJX29b")
        const products = {
            products: [
                {
                    "name": `${car?.model.brand.name} ${car?.model.name}`,
                    "description": `Serie: ${car?.vin_number}`,
                    "quantity": 1,
                    "price": 500000,
                    "images": gallery[0].original
                }
            ]
        };

        try {
            const sessionId = await handleProduct(products);
            const stripe = await stripePromise;

            // Redirigir al formulario de pago de Stripe
            const result = await stripe.redirectToCheckout({
                sessionId: sessionId,
            });

            if (result.error) {
                console.log(result.error);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const sendForm = () => {
        if (isAuthenticated) {

            sendpayment();
        } else {
            setOpenModal(true);
        }

    }


    return <>
        <div className="container mt-8 mx-auto px-4 pb-16 car-detail min-h-[100vh]">
            <div className="w-full">

                <div className="flex flex-row md:flex-row w-full">

                    <div className="w-100 md:w-3/5 px-2 md:px-0 md:pr-4 min-h-full">
                        {loading ? (
                            <Skeleton variant="rectangular" height={450} />
                        )
                            : (
                                <div className="">
                                    <ImageGallery items={gallery} className="w-full" />
                                </div>
                            )}
                    </div>

                    <div className="w-100 md:w-2/5 flex-none text-gray-400 mt-8 text-center md:text-left ">

                        {
                            loading ? (<LoadingSkeletonCarDetail />)
                                : (

                                    <div>

                                        {car?.inventory === 'FISICO' ? <div className="flex sm:justify-center md:justify-start place-content-center">
                                            <Certificate
                                                width="20"
                                            />
                                        </div> : null}

                                        <div className="text-3xl text-gray-800 font-semibold">
                                            {car?.model.brand.name} {car?.model.name}
                                        </div>

                                        <div className="text-2xl text-gray-800">
                                            {car?.version}
                                        </div>

                                        <div className="text-2xl text-gray-500">
                                            {car?.year} / {numberFormat(+car?.km)} Km
                                        </div>


                                        <div className="grid justify-center">
                                            <div className="text-6xl pt-8 pb-2 text-gray-900 font-bold price">
                                                ${numberFormat(+car?.price)}
                                            </div>

                                            <div className="flex justify-center mb-4">
                                                <Chip
                                                    className="monthly"
                                                    label={`Desde $${numberFormat(+car.monthly_installment)} / Mes`}
                                                />

                                                <Chip
                                                    className="status ml-2"
                                                    label={car?.vehicle_transaction_status || 'PENDIENTE'}
                                                />


                                            </div>
                                        </div>

                                        <div className="text-center mt-8">
                                            <Button
                                                className="action-button"
                                                variant="outlined"
                                                size="large"
                                                startIcon={<ShoppingCartIcon />}
                                                sx={{
                                                    backgroundColor: 'black',
                                                    borderRadius: '20px',
                                                    color: '#00CE7C',
                                                    padding: "15px 80px",
                                                    minWidth: { sm: 190, md: 209 },
                                                    "&:hover": {
                                                        borderColor: '#000',
                                                        color: '#000',
                                                    },
                                                }}
                                                onClick={sendForm}

                                            >

                                                Apartado Digital
                                            </Button>



                                        </div>

                                        <div className="flex justify-center mt-8">
                                            <div className="px-2">
                                                <ButtonsCarDetail
                                                    className="action-button"
                                                    variant="outlined"
                                                    size="large"
                                                    startIcon={< VisibilityIcon />}
                                                    onClick={() => {
                                                        if (car && car['video-link']) {
                                                            window.open(car['video-link'])
                                                        }
                                                    }}
                                                >
                                                    Ver Video
                                                </ButtonsCarDetail>
                                            </div>

                                            <div className="px-2">
                                                <ButtonsCarDetail
                                                    className="action-button"
                                                    variant="outlined"
                                                    size="large"
                                                    startIcon={<DownloadForOfflineIcon />}
                                                    onClick={() => {
                                                        if (car && car['download-link']) {
                                                            window.open(car['download-link'])
                                                        }
                                                    }}
                                                >
                                                    Ficha Tecnica
                                                </ButtonsCarDetail>
                                            </div>
                                        </div>
                                        <div className="flex justify-center mt-8">
                                            <div className="px-2">
                                                <ButtonsCarDetail
                                                    className="action-button sm:text-sm"
                                                    variant="outlined"
                                                    size="large"
                                                    startIcon={<CalendarMonthIcon />}
                                                    onClick={() => {
                                                        setShowForm(!showForm)
                                                        setPrePaymentForm(false)
                                                    }}
                                                >Agenda Cita
                                                </ButtonsCarDetail>
                                            </div>
                                            <div className="px-2">
                                                <ButtonsCarDetail
                                                    className="action-button"
                                                    variant="outlined"
                                                    size="large"
                                                    startIcon={<VideoCall />}
                                                    onClick={openZoom}
                                                >
                                                    Conoce Por Zoom
                                                </ButtonsCarDetail>
                                            </div>
                                        </div>




                                        <LoginModal
                                            setOpenModal={setOpenModal}
                                            openModal={openModal}
                                            setDatalogin={setDatalogin}
                                            datalogin={datalogin}
                                            sendpayment={sendpayment}
                                        />




                                        {/* {showPrePaymentForm ? <div className='mt-8'>
                                            <div className="text-center" >
                                                <Button
                                                    variant="contained"
                                                    className="btn-primary rounded-full w-64"
                                                    sx={{
                                                        "border-radius": '20px',

                                                    }}
                                                    onClick={handleSubmit}
                                                >
                                                    {'Apartar'}
                                                </Button>
                                            </div>
                                            <Typography className='py-4 text-2xl text-black'>
                                                Seguridad en tus pagos
                                            </Typography>
                                            <Typography className='py-4 text-sm text-black'>
                                                *Antes de pagar con su tarjeta de débito o crédito: Con este apartado digital y el VIN(serie) reservará el automóvil de su preferencia hasta por 7 días naturales. Después del VIN#: Su pago REEMBOLSABLE de $ 5,000/ $ 10,000 será devuelto si no lleva a término final la compra de su vehículo Nowy.Mx.
                                            </Typography>
                                            <Typography className='py-4 text-sm text-black'>
                                                Es importante ingresar todos los datos que le son requeridos por el sistema a continuación:
                                            </Typography>

                                        </div> : null} */}

                                        {showForm ? <div className='py-4'>
                                            <Typography className='py-4 text-2xl text-black'>
                                                Agende cita con los expertos
                                            </Typography>

                                            <div className='pb-2'>
                                                <Input
                                                    fullWidth
                                                    disableUnderline
                                                    className="px-2 py-1 rounded-full border border-gray-500"
                                                    value={name}
                                                    placeholder="Nombre o Razón Social"
                                                    type="text"
                                                    onChange={(event) => {
                                                        setName(event.target.value)
                                                        setNameError('')
                                                    }}
                                                />
                                                <FormHelperText className="error-label">{nameError}</FormHelperText>
                                            </div>

                                            <div className='pb-2'>
                                                <Input
                                                    fullWidth
                                                    disableUnderline
                                                    className="px-2 py-1 rounded-full border border-gray-500"
                                                    value={tel}
                                                    placeholder="Teléfono de Contacto"
                                                    type="tel"
                                                    onChange={(event) => {
                                                        setTel(event.target.value)
                                                        setTelError('')
                                                    }}
                                                />

                                                <FormHelperText className="error-label">{telError}</FormHelperText>
                                            </div>



                                            <div>
                                                <Input
                                                    fullWidth
                                                    disableUnderline
                                                    className="px-2 py-1 rounded-full border border-gray-500"
                                                    value={email}
                                                    placeholder="Correo Electrónico"
                                                    onChange={(event) => {
                                                        setEmail(event.target.value)
                                                        setEmailError('')
                                                    }}
                                                />
                                                <FormHelperText className="error-label">{emailError}</FormHelperText>
                                            </div>
                                        </div> : null}

                                        {!showConfirmation ?
                                            <div
                                                className={`text-center ${showForm ? '' : 'mt-4'}`}
                                            >
                                                {!showForm ? "" : <Button
                                                    variant="contained"
                                                    className="btn-primary rounded-full w-64"
                                                    sx={{
                                                        "border-radius": '20px',
                                                    }}

                                                    onClick={() => {
                                                        sendBuyRequest()
                                                        setShowConfirmation(true)
                                                        setShowForm(false)
                                                    }}

                                                >
                                                    {'ENVIAR'}
                                                </Button>}
                                            </div>
                                            : null}

                                        {showConfirmation ? <div className='mt-4'>
                                            <p className="py-2 text-md">
                                                Para continuar el proceso de compra nuestro teleasesor se pondrá en contacto con usted.
                                            </p>

                                            <p className="py-2 text-md">
                                                O bien si lo desea puede iniciar una conversación AHORA MISMO con nuestro centro de atención a clientes o llamar al movil 6693830570.
                                            </p>

                                            <div className="flex justify-center py-4">
                                                <SocialIcon
                                                    url={sendWhatsapp()}
                                                    network="whatsapp"
                                                    fgColor="#fff"
                                                    style={{
                                                        height: 60,
                                                        width: 60,
                                                    }}
                                                    target="_blank"
                                                />

                                                <a href={zoomUrl} className="ml-4" target="_blank" rel="noreferrer">
                                                    <img src={zoom} width='60' alt='zoom' />
                                                </a>
                                            </div>

                                            <div className="mt-2 p-2 border">
                                                <p className="py-2 text-md succes-text">
                                                    <strong>Datos enviados</strong>
                                                </p>

                                                <p>
                                                    <strong>Nombre o Razón Social: </strong>
                                                    <span>{name}</span>
                                                </p>

                                                <p>
                                                    <strong>Teléfono de Contacto: </strong>
                                                    <span>{tel}</span>
                                                </p>

                                                <p>
                                                    <strong>Correo Electrónico: </strong>
                                                    <span>{email}</span>
                                                </p>
                                            </div>
                                        </div> : null}
                                    </div>)}

                    </div>
                </div>

                {!loading && (
                    <SpecsCarDetail car={car} />
                )}

            </div>
        </div>

    </>;
}

export default CarDetail;