import { React, useState, useRef, useEffect, useContext, useCallback } from 'react'
import Header from '../../../shared/Header/Header';
import { useForm } from 'react-hook-form';
import { sendOpt, verifyOpt } from '../../../../services/loginOpt';
import { AuthContext } from '../../../context/AuthContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import fondologin from '../../../../assets/images/fondo-login.jpg'





export default function LoginModal({ openModal, setOpenModal, setDatalogin, datalogin, sendpayment }) {

    const { register, setValue, watch, formState: { errors }, handleSubmit } = useForm();

    const [formConfirmation, setFormConfirmation] = useState(false);
    const [code, setCode] = useState(['', '', '', '']);
    const [timeLeft, setTimeLeft] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const { setUser, login } = useContext(AuthContext);



    // const { login } = useAuth();

    const numeroCelular = watch('numerocelular', '');

    const inputRefs = useRef([]);


    const enviarInfo = async (data) => {
        setDatalogin(data);
        setFormConfirmation(true);
        setTimeLeft(60);
        setCanResend(false);
        setCode(['', '', '', ''])
        setOtpError(false);


        try {
            await sendOpt({
                nombre: data.nombre,
                telefono: data.numerocelular,
                codigo_postal: data.codPostal,
            });

        } catch (error) {
            console.log(error.message);
        }
    };

    const confirmarOTP = useCallback(async (codeValue) => {
        const fullCode = codeValue.join('');
        try {
            const response = await verifyOpt({
                telefono: datalogin.numerocelular,
                otp: fullCode,
            });

            if (response && response.status === 201) {

                login({ name: datalogin.nombre })
                setUser({ name: datalogin.nombre });

                sendpayment();
            } else {
                setOtpError(true);
            }
        } catch (error) {
            setOtpError(true);
            setCode(['', '', '', '']);
        }
    }, [datalogin.numerocelular, datalogin.nombre, sendpayment, setUser, login]);







    const handleInputChange = (e, index) => {
        const value = e.target.value;

        if (/^\d$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        } else {
            const newCode = [...code];
            newCode[index] = '';
            setCode(newCode);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !e.target.value && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };


    useEffect(() => {

        if (code.every((digit) => digit !== '')) {
            confirmarOTP(code);
        }
    }, [code, confirmarOTP]);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setCanResend(true);
        }
    }, [timeLeft]);

    const resendCode = () => {
        enviarInfo(datalogin)

        setTimeLeft(60);
        setCanResend(false);
        setCode(['', '', '', ''])
        setOtpError(false);
    };



    if (!openModal) return null;


    return (

        <>
            <div className="bg-black  fixed top-0 left-0 w-full" style={{ zIndex: 20 }}>


                <div className="grow">
                    <div className="bg-black">
                        <Header />
                    </div>
                </div>
            </div>


            <div className=" overflow-x-auto min-w-[320px] max-w-full min-h-screen fixed inset-0 bg-black bg-opacity- top-10  flex items-center justify-center lg:justify-end z-10 bg-cover bg-center  " style={{ backgroundImage: `url(${fondologin})` }}>
                <div className="w-full md:max-w-lg lg:mr-40  max-w-sm md:p-0 px-5 ">



                    {!formConfirmation ? (

                        <form className="relative bg-white shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4 " onSubmit={handleSubmit(enviarInfo)}>

                            <button type="button" class="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal"
                                onClick={() => {
                                    setOpenModal(false);
                                    setFormConfirmation(false);
                                    setTimeLeft(60);
                                    setCanResend(false);

                                }}
                            >
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>

                            <h3 className='font-title text-lg md:text-xl text-brandPrimary text-center  mb-[5px] font-bold '>
                                ¡Cada vez más cerca de tu vehículo!
                            </h3>

                            <h4 className='font-subtitle text-xs md:text-sm text-start mb-[20px] font-bold'>
                                Conoce el precio de enganche mínimo para el vehículo seleccionado
                            </h4>

                            <div className="mb-2">


                                <input
                                    className={`appearance-none border ${errors.nombre ? 'border-red-500 placeholder-red-500' : 'border-green-500'} border-green-500 rounded-lg w-56 py-2 px-3 text-gray-700 `}
                                    id="nombre"
                                    type="text"
                                    placeholder="Nombre"
                                    {...register("nombre", { required: true })}
                                />



                            </div>

                            <div className="mb-2">

                                <PhoneInput
                                    country={'mx'}
                                    value={numeroCelular}
                                    placeholder='Num de Telefono'
                                    // inputClass={`shadow-lg appearance-none ${errors.numerocelular ? 'border-2 border-red-500 placeholder-red-500' : 'border-2 border-green-500'} rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                    onChange={(phone) => {
                                        let phoneNumberWithPrefix = phone ? phone : '';


                                        if (phoneNumberWithPrefix && !phoneNumberWithPrefix.startsWith('+')) {
                                            phoneNumberWithPrefix = '+' + phoneNumberWithPrefix;
                                        }


                                        setValue('numerocelular', phoneNumberWithPrefix, { shouldValidate: true });
                                    }}
                                    international

                                    inputStyle={{
                                        border: errors.numerocelular ? '2px solid red' : '1.5px solid #00ce7c',
                                        borderRadius: '8px',
                                        width: '14rem',
                                        height: '40px',

                                    }}

                                    buttonStyle={{
                                        border: errors.numerocelular ? '2px solid red' : '1.5px solid #00ce7c',
                                        transition: 'none',




                                    }}
                                />

                            </div>

                            <input
                                type="hidden"
                                {...register('numerocelular', { required: true })}
                            />
                            {/* <div className='mb-4'>

                                <PhoneInput
                                    country={'mx'}
                                    // onChange={phone => this.setState({ phone })}
                                    // disableCountryCode={true}
                                    placeholder='Num de Célular'
                                />
                            </div> */}



                            <div className="mb-4">

                                <input
                                    className={`shadow appearance-none border ${errors.codPostal ? 'border-red-500 placeholder-red-500' : 'border-green-500'} border-green-500 rounded-lg w-56 py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline`}
                                    id="codPostal"
                                    type="number"
                                    placeholder="Código Postal"
                                    {...register("codPostal", { required: true })}
                                />
                            </div>


                            <div className="flex items-center md:justify-between justify-center mb-2 ">
                                <button

                                    style={{
                                        color: '#000000',
                                        backgroundColor: '#00ce7c',
                                        borderRadius: '16px',
                                        padding: '0.5rem 1rem',
                                        transition: 'background-color  0.3s',
                                    }}
                                    onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#005F47'; }}
                                    onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = '#00ce7c'; }}

                                    type="submit"
                                >
                                    Continuar
                                </button>

                            </div>
                            <div className='mb-3'>

                                <p className=" mb-4 text-justify text-gray-500 " style={{ fontSize: '11px' }} > Al dar clic en “Continuar” confirmas que has leído y aceptado nuestro
                                    <a
                                        href="https://nowy.mx/politicas-de-privacidad"
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:underline mx-1"

                                    >
                                        Aviso de Privacidad
                                    </a>

                                </p>
                                <p className="text-justify  font-bold text-black" style={{ fontSize: '10px' }}>Realice el apartado de su vehículo con el monto de enganche expuesto a continuación. A la brevedad será contactado por uno de nuestros agentes, su vehículo será reservado hasta por 7 días naturales. Su pago puede ser reembolsable en caso de no llegar al término final de la compra. </p>
                            </div>

                        </form>) : (

                        <form className="relative bg-white shadow-md rounded-3xl px-8 pt-6 pb-40 mb-4 " onSubmit={handleSubmit(enviarInfo)}>

                            <button type="button" class="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal"
                                onClick={() => {
                                    setOpenModal(false);
                                    setFormConfirmation(false);
                                    setTimeLeft(60);
                                    setCanResend(false);

                                }}
                            >
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>

                            <h3 className='font-title text-xl text-brandPrimary text-start mb-[10px] font-bold'>
                                Confirma tu celular
                            </h3>
                            <div className='mb-3'>

                                <p className=" mb-4 text-start  text-gray-500 font-bold pr-20" style={{ fontSize: '12px' }}  > Ingresa el código que se ha enviado por SMS al número {datalogin.numerocelular}
                                    <span
                                        onClick={() => {
                                            setFormConfirmation(false);
                                            setTimeLeft(60);
                                            setCanResend(false);
                                        }}
                                        className="text-blue-600 cursor-pointer hover:underline ml-2"
                                    >Cambiar
                                    </span>

                                </p>
                            </div>



                            <div className="flex justify-start space-x-2 mb-4">
                                {[0, 1, 2, 3].map((i) => (
                                    <input
                                        key={i}
                                        type="text"
                                        maxLength="1"
                                        className={`w-10 h-10 text-center text-xl border rounded-md focus:outline-none focus:ring-2 ${otpError ? 'border-red-500 ring-red-500' : 'border-green-300 focus:ring-brandPrimary'
                                            }`}
                                        onChange={(e) => handleInputChange(e, i)}
                                        onKeyDown={(e) => handleKeyDown(e, i)}
                                        ref={(el) => (inputRefs.current[i] = el)}
                                        value={code[i]}
                                    />
                                ))}
                            </div>
                            <div className="mb-3">
                                <p className=" text-gray-500" style={{ fontSize: '11px' }} >
                                    {canResend ? (
                                        <button onClick={resendCode} className="text-brandPrimary hover:underline">
                                            Reenviar código
                                        </button>
                                    ) : (
                                        `Reenviar código ${timeLeft} `
                                    )}
                                </p>
                            </div>


                        </form>)}

                </div>
            </div>





        </>
    )
}
