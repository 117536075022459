import { Breadcrumbs, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'

export const PreguntasBreadcrumbs = ({ items }) => {

    const [links, setLinks] = useState([
        {
            title: 'Preguntas frecuentes',
            slug: 'preguntas-frecuentes'
        },
    ])
    
    useEffect(() => {
        setLinks((prevLinks) => {
            const uniqueItems = items.filter(item => 
                !prevLinks.some(link => link.slug === item.slug)
            );
            return [...prevLinks, ...uniqueItems];
        });
    }, [items])
    

    
    
    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{mt: 2, paddingX: {xs: 3, lg: 5}}}>
            {links.map((item, index) => {
                // Si es el último elemento, lo pintamos de azul
                const isLast = index === links.length - 1;
                return isLast ? (
                    <Typography
                        key={index}
                    >
                        {item.title}
                    </Typography>
                ) : (
                    <Link
                        key={index}
                        sx={{color: '#00ce7c',textDecoration: 'none'}}
                        href={`/${item.slug}`} // Puedes agregar la URL correspondiente aquí
                    >
                        {item.title}
                    </Link>
                );
            })}
        </Breadcrumbs>
    )
}
