import axios from 'axios';

export const listPreguntas = async() => {
    return axios.get('https://blog.nowy.mx/wp-json/wp/v2/preguntas_frecuentes?&_embed')
    .then((response) => response)
    .then((response) => response.data)
    .catch((error) => console.log(error));
}

export const listCategoria = async() => {
    return axios.get('https://blog.nowy.mx/wp-json/wp/v2/categoria')
    .then((response) => response)
    .then((response) => response.data)
    .catch((error) => console.log(error));
}

export const categoriaExists = async(slug) => {
    return axios.get(`https://blog.nowy.mx/wp-json/wp/v2/categoria?slug=${slug}`)
    .then((response) => response)
    .then((response) => response.data)
    .catch((error) => console.log(error));
}

export const preguntasByCategoria = async(slug) => {
    return axios.get(`https://blog.nowy.mx/wp-json/wp/v2/preguntas_frecuentes?categoria=${slug}&_embed`)
    .then((response) => response)
    .then((response) => response.data)
    .catch((error) => console.log(error));
}

export const preguntaExists = async(slug) => {
    return axios.get(`https://blog.nowy.mx/wp-json/wp/v2/preguntas_frecuentes?slug=${slug}&_embed`)
    .then((response) => response)
    .then((response) => response.data)
    .catch((error) => console.log(error));
}

export const preguntaLike = async(id) => {
    return axios.post(`https://blog.nowy.mx/wp-json/preguntas_frecuentes/v1/like/${id}`)
    .then((response) => response)
    .then((response) => response.data)
    .catch((error) => console.log(error));
}

export const preguntaDislike = async(id) => {
    return axios.post(`https://blog.nowy.mx/wp-json/preguntas_frecuentes/v1/dislike/${id}`)
    .then((response) => response)
    .then((response) => response.data)
    .catch((error) => console.log(error));
}