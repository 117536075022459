// components
import {
    Box,
    Typography,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

// import coupe from '../../../assets/images/type-vehicles/home-modelo_coupe_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
// import hatchback from '../../../assets/images/type-vehicles/home-modelo_hatchback_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
// import jeep from '../../../assets/images/type-vehicles/home-modelo_jeep_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
// import pickup from '../../../assets/images/type-vehicles/home-modelo_pickup_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
// import roadster from '../../../assets/images/type-vehicles/home-modelo_roadster_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
// import sedan from '../../../assets/images/type-vehicles/home-modelo_sedan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
// import suv from '../../../assets/images/type-vehicles/home-modelo_suv_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'

import coupe from '../../../assets/images/type-vehicles/home-modelo_coupe_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import hatchback from '../../../assets/images/type-vehicles/home-modelo_hatchback_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import jeep from '../../../assets/images/type-vehicles/home-modelo_jeep_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import pickup from '../../../assets/images/type-vehicles/home-modelo_pickup_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import roadster from '../../../assets/images/type-vehicles/home-modelo_roadster_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import sedan from '../../../assets/images/type-vehicles/home-modelo_sedan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import suv from '../../../assets/images/type-vehicles/home-modelo_suv_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'



import { Image } from '../../shared/Image/Image'
import { PreloadImages } from '../../shared/PreloadImages/PreloadImages'
// type vehicles order
const typeVehicles = [
    {
        image: sedan,
        alt: 'home-modelo_sedan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Sedan',
    },
    {
        image: suv,
        alt: 'home-modelo_suv_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'SUV/Familiar',
    },
    {
        image: roadster,
        alt: 'home-modelo_roadster_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Roadster',
    },
    {
        image: pickup,
        alt: 'home-modelo_pickup_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Pickup',
    },
    {
        image: hatchback,
        alt: 'home-modelo_hatchback_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Hatchback',
    },
    {
        image: coupe,
        alt: 'home-modelo_coupe_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Coupe',
    },
    {
        image: jeep,
        alt: 'home-modelo_jeep_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Jeep',
    },
]

// component
function HomeVehicles() {
    return <Box className="container-fluid"
        sx={{
            backgroundColor: '#f7f7f7',
        }}
    >
        <PreloadImages images={typeVehicles} />
        <Box className="container p-[20px]">
            <h4 className='font-subtitle text-xl text-center mb-[20px]'>
                Encuentra tu tipo de auto
            </h4>

            <Box className='flex overflow-x-auto pb-[10px]'>
                {typeVehicles.map((typeVehicle, index) => <Box
                    key={`type-vehicle-${index}`}
                    className='px-[8px] py-[8px] drop-shadow-lg mr-[20px]'
                >
                    <Box className='rounded-lg w-[150px] grid place-items-center'>
                        <RouterLink to={`/compra-tu-auto?tipo=${typeVehicle.label}`}>
                            <Image image={typeVehicle.image} alt={typeVehicle.alt} style={{ width: '150px', height: '112px' }} />
                            <Typography className='drop-shadow-lg block text-center bg-[#fffdfd]' variant="caption" gutterBottom >
                                {typeVehicle.label}
                            </Typography>
                            {/* {typeVehicle.image} */}
                        </RouterLink>
                    </Box>
                </Box>)}
            </Box>
        </Box>
    </Box>
}

// export
export default HomeVehicles
