import axios from 'axios';

export const sendOpt = async(sendOptData) => {
    return axios.post('/api/send-otp', sendOptData)
    .then((response) => response)
    .catch((error) => console.log(error));
}

export const verifyOpt = async(verifyOptData) => {
    return axios.post('/api/verify-otp', verifyOptData)
    .then((response) => response)
    .catch((error) => console.log(error));
}