// react
import { useEffect, useRef, useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    Button,
    Chip,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Skeleton,
    Slider,
    TextField,
} from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import { Box } from '@mui/system'

import ReactPixel from 'react-facebook-pixel';




// helpers
import catalogsHelper from '../../helpers/catalogs'

// services
import catalogsService from '../../services/catalogsService'
import searchService from '../../services/searchService'
import CarCard from './CarCard/CarCard'
import { Seo } from '../shared/Seo/Seo'

import { PaginationRounded } from './CarCard/PaginationRounded'

// private vars
const { sortCatalog } = catalogsHelper
const numberFormat = (number) => number.toLocaleString('es-MX')
const currentYear = +(new Date().getFullYear())
const yearsOptions = [...Array((currentYear + 10) - currentYear + 1).keys()].map(x => currentYear - x)
const passengersOptions = [...Array(7).keys()].map(n => n + 2).concat(12);
const minPriceFilter = 100000
const stepPriceFilter = minPriceFilter / 2
const maxPriceFilter = 750000

const minKmFilter = 0;
const stepKmFilter = 1;
const maxKmFilter = 200000;

const marksPrice = [
    {
        value: 100000,
        label: '$100,000',
    },
    {
        value: 750000,
        label: '$750,000',
    },
]

const marksKm = [
    {
        value: 0,
        label: '0 km',
    },
    {
        value: 200000,
        label: '200,000 km',
    },
]

const typeOptions = [
    {
        id: 1,
        name: 'Sedan',
    },
    {
        id: 2,
        name: 'SUV/Familiar',
    },
    {
        id: 3,
        name: 'Sport',
    },
    {
        id: 4,
        name: 'Comercial',
    },
    {
        id: 5,
        name: 'SUV Sport',
    },
    {
        id: 6,
        name: 'Premium',
    },
    {
        id: 7,
        name: 'Off Road',
    },
    {
        id: 8,
        name: 'Pickup',
    },
    {
        id: 9,
        name: 'Hatchback',
    },
    {
        id: 10,
        name: 'Passangers',
    },
]
const stateOptions = [
    {
        id: 1,
        name: 'Nuevo',
    },
    {
        id: 2,
        name: 'Seminuevo',
    },

];

const transmitionOptions = [
    {
        id: 1,
        name: 'Automatica',
    },
    {
        id: 2,
        name: 'Estandar',
    },
    {
        id: 5,
        name: 'Estandar/Manual',
    },
    {
        id: 6,
        name: 'Smart Joystick',
    },
    {
        id: 7,
        name: 'CVT',
    },
    {
        id: 8,
        name: 'DSG',
    },
    {
        id: 9,
        name: 'DCT',
    },
    {
        id: 10,
        name: 'Tiptronic',
    },
    {
        id: 13,
        name: 'Sport',
    }
];

const colorOptions = [
    { id: 1, name: 'Rojo' },
    { id: 2, name: 'Blanco' },
    { id: 3, name: 'Plata' },
    { id: 4, name: 'Gris' },
    { id: 5, name: 'Azul' },
    { id: 6, name: 'Negro' },
    { id: 7, name: 'Gris mercurio' },
    { id: 8, name: 'Azul relámpago' },
    { id: 9, name: 'Blanco oxford' },
    { id: 10, name: 'Gris hierro' },
    { id: 11, name: 'Rojo rubí' },
    { id: 12, name: 'Azul metrópoli' },
    { id: 13, name: 'Azul atlas' },
    { id: 14, name: 'Acero' },
    { id: 15, name: 'Beige' },
    { id: 16, name: 'Café' },
    { id: 17, name: 'Marrón' },
    { id: 18, name: 'Naranja' },
    { id: 19, name: 'Turquesa' },
    { id: 31, name: 'Gris negro' },
    { id: 32, name: 'Verde' },
    { id: 33, name: 'Crema' },
    { id: 34, name: 'Azul Eléctrico' },
    { id: 35, name: 'Azul Metálico' },
    { id: 36, name: 'Azul Galaxia' },
    { id: 37, name: 'Azul Zafiro' },
    { id: 38, name: 'Oro' },
    { id: 39, name: 'Gris Oscuro' },
    { id: 40, name: 'Plata-Blanco' },
    { id: 41, name: 'Azul Eléctrico' },
    { id: 42, name: 'Champagne' },
    { id: 43, name: 'Gris Platino' },
    { id: 44, name: 'Gris' },
    { id: 45, name: 'Púrpura' }
];





// component
function CarsForSale() {
    // router
    const [searchParams, setSearchParams] = useSearchParams({})
    const navigate = useNavigate()

    // refs
    const dataFetchedRef = useRef(false)

    // state
    const [cars, setCars] = useState([])
    const [productPerpege] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [shownFilters, setShownFilters] = useState(false)
    const [loading, setLoading] = useState(true)
    const [filterTags, setFilterTags] = useState({})
    const [brandsOptions, setBrandsOptions] = useState([])
    const [brandsFilter, setBrandsFilter] = useState([])
    const [modelsOptions, setModelsOptions] = useState([])
    const [modelsFilter, setModelsFilter] = useState([])
    const [priceFilter, setPriceFilter] = useState([minPriceFilter, maxPriceFilter])
    const [kmFilter, setKmFilter] = useState([minKmFilter, maxKmFilter]);
    const [isMobile, setIsMobile] = useState(false);
    const [openFilters, setOpenFilters] = useState({
        brands: false,
        models: false,
        years: false,
        types: false,
        states: false,
        transmitions: false,
        colors: false,
        passengers: false,
        prices: false,
        kms: false,

    });

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        ReactPixel.trackCustom('Contenido Catálogo Compra');
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);


    const toggleOpen = (filter) => {

        setOpenFilters((prevState) => ({
            ...prevState,
            [filter]: !prevState[filter],
        }));
    };



    useEffect(() => {
        setCurrentPage(1);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setOpenFilters({
            brands: false,
            models: false,
            years: false,
            types: false,
            states: false,
            transmitions: false,
            colors: false,
            passengers: false,
            prices: false,
            kms: false,
        });
    }, [filterTags]);

    const totalProduct = cars.length;

    const indexOfLastProduct = currentPage * productPerpege;
    const indexOfFirstProduct = indexOfLastProduct - productPerpege;
    const currentCars = cars.slice(indexOfFirstProduct, indexOfLastProduct);


    // callbacks
    const getCarsParams = useCallback(() => {
        setLoading(true)

        const fetchData = () => {
            catalogsService.brands().then(brands => {
                setBrandsOptions(sortCatalog(brands))
                setBrandsFilter(brands)
            })
        }

        if (!dataFetchedRef.current) {
            dataFetchedRef.current = true
            fetchData()
        }

        const query = searchParams.entries()
        let filter = ''
        let tags = {}
        let brandValue = null
        let modelValue = null
        let filtersData = {};

        for (const [key, value] of query) {
            filtersData[key] = value;
            switch (key) {
                case 'marca':
                    filter += `${filter === '' ? '' : '&'}brandId=${value}`
                    brandValue = value
                    break

                case 'modelo':
                    filter += `${filter === '' ? '' : '&'}modelId=${value}`
                    modelValue = value
                    break


                case 'transmision':
                    for (const transmision of transmitionOptions) {
                        if (transmision.id === Number(value) || transmision.name === value) {
                            filter += `${filter === '' ? '' : '&'}transmissionId=${transmision.id}`
                            tags.transmision = transmision.name
                        }
                    }
                    break

                case 'pasajeros':
                    filter += `${filter === '' ? '' : '&'}passengersNumber=${value}`;
                    tags['pasajeros'] = value;
                    break;

                case 'color':
                    for (const color of colorOptions) {
                        if (color.id === Number(value) || color.name === value) {
                            filter += `${filter === '' ? '' : '&'}color=${color.id}`
                            tags.color = color.name
                        }
                    }
                    break

                case 'tipo':
                    for (const type of typeOptions) {
                        if (type.id === Number(value) || type.name === value) {
                            filter += `${filter === '' ? '' : '&'}typeId=${type.id}`
                            tags.tipo = type.name
                        }
                    }
                    break

                case 'año':
                    filter += `${filter === '' ? '' : '&'}year=${value}`
                    tags['año'] = value
                    break

                case 'estado':
                    const kmThreshold = 1000;
                    const estadoFilter = value === 'Nuevo' ? `<${kmThreshold}` : `>=${kmThreshold}`;
                    filter += `${filter === '' ? '' : '&'}km=${estadoFilter}`;
                    tags.estado = value;
                    break;

                case 'precio':
                    const priceRange = value.split('-')
                    const minPrice = parseInt(priceRange[0], 10)
                    const maxPrice = parseInt(priceRange[1], 10)

                    filter += `${filter === '' ? '' : '&'}pricemin=${minPrice}&&pricemax=${maxPrice}`
                    tags.precio = `$${numberFormat(minPrice)} - $${numberFormat(maxPrice)}`
                    setPriceFilter([minPrice, maxPrice])
                    break

                case 'kilometraje':
                    const kmRange = value.split('-');
                    const minKm = parseInt(kmRange[0]);
                    const maxKm = parseInt(kmRange[1]);

                    filter += `${filter === '' ? '' : '&'}kmmin=${minKm}&&kmmax=${maxKm}`;
                    tags.kilometraje = `${numberFormat(minKm)} km - ${numberFormat(maxKm)} km`;
                    setKmFilter([minKm, maxKm]);
                    break;

                default: { }

            }
        }

        ReactPixel.trackCustom('todos los filtros', filtersData);
        console.log('Filtros enviados a Meta Pixel:', filtersData);

        if (brandValue) {
            catalogsService.brands().then(brands => {
                brands = sortCatalog(brands)

                for (const brand of brands) {
                    if (brand.id === Number(brandValue) || brand.name === brandValue) {
                        filter += `${filter === '' ? '' : '&'}brandId=${brand.id}`
                        tags.marca = brand.name

                        // reset models
                        setModelsOptions([])
                        setBrandsFilter(brands)

                        // search models for brand
                        // eslint-disable-next-line
                        catalogsService.models(brand.id).then(models => {
                            models = sortCatalog(models)

                            setModelsOptions(models)
                            setModelsFilter(models)

                            if (modelValue) {
                                for (const model of models) {
                                    if (model.id === Number(modelValue) || model.name === modelValue) {
                                        filter += `${filter === '' ? '' : '&'}modelId=${model.id}`
                                        tags.modelo = model.name

                                        setFilterTags(tags)
                                        getCars(filter)
                                        return
                                    }
                                }
                            }

                            setFilterTags(tags)
                            getCars(filter)
                            return
                        })
                    }
                }

                setFilterTags(tags)
                getCars(filter)
            })
            return
        }

        setFilterTags(tags)
        getCars(filter)
    }, [searchParams])

    // effects
    useEffect(() => {
        getCarsParams()
    }, [getCarsParams])

    // functions
    function getCars(filters = null) {
        searchService.search(filters ? filters : '').then(search => {
            let cars = search.data ? search.data : search
            setCars(!!cars.length ? cars : Object.values(cars))
            setLoading(false)
        })
    }

    function selectBrand(brand) {
        closeFilters()
        setLoading(true)
        setModelsOptions([])
        setBrandsFilter(brandsOptions)
        catalogsService.models(brand.id).then(models => {
            setModelsOptions(sortCatalog(models))
            setModelsFilter(sortCatalog(models))
        })

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        if (search.modelo) {
            delete search.modelo
        }

        setSearchParams({
            ...search,
            marca: brand.name,
        })
    }

    function selectModel(model) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            modelo: model.name,
        })
    }

    function selectType(type) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            tipo: type.name,
        })
    }

    function applyPriceFilter() {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            precio: `${priceFilter[0]}-${priceFilter[1]}`,
        })
    }

    function applyKmFilter() {
        closeFilters();
        setLoading(true);

        const search = {};
        const query = searchParams.entries();
        for (const [key, value] of query) {
            search[key] = value;
        }

        setSearchParams({
            ...search,
            kilometraje: `${kmFilter[0]}-${kmFilter[1]}`,
        });
    }

    function selectState(state) {
        closeFilters();
        setLoading(true);

        const search = {};
        const query = searchParams.entries();
        for (const [key, value] of query) {
            search[key] = value;
        }

        setSearchParams({
            ...search,
            estado: state.name,
        });
    }

    function selectTransmition(transmition) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            transmision: transmition.name,
        });
    }


    function selectPassenger(passenger) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            pasajeros: passenger,
        });
    }

    function selectColors(colors) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            color: colors.name,
        });
    }






    const closeFilters = () => {
        setShownFilters(false)
    }

    const goToCar = (car) => {
        navigate(`/auto/${prettyUrl(car.model.name)}/${car.year}/${car.id}`)
    }

    const prettyUrl = (value) => {
        return value.replace(/ /g, "-")
            .replace(/@/g, "")
            .replace(/\$/g, "")
            .replace(/!/g, "")
            .replace(/#/g, "")
            .toLowerCase()
    }

    const toggleFilters = () => {
        setShownFilters(!shownFilters)
    }

    const deleteFilter = (removeKey) => {
        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            if (key !== removeKey) {
                search[key] = value
            }
        }

        setLoading(true)
        setSearchParams(search)
    }

    const handleBrandFilterChange = (e) => {
        const search = e.target.value
        setBrandsFilter(brandsOptions.filter(brand => brand.name.toLowerCase().includes(search.toLowerCase())))
    }

    const handleModelFilterChange = (e) => {
        const search = e.target.value
        setModelsFilter(modelsOptions.filter(model => model.name.toLowerCase().includes(search.toLowerCase())))
    }

    function selectYear(year) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            año: year,
        })
    }

    const minPriceDistance = 10000

    const handlePriceFilterChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return
        }

        if (activeThumb === 0) {
            setPriceFilter([Math.min(newValue[0], priceFilter[1] - minPriceDistance), priceFilter[1]])
        } else {
            setPriceFilter([priceFilter[0], Math.max(newValue[1], priceFilter[0] + minPriceDistance)])
        }
    }

    const valuePrice = (value) => {
        return `$${numberFormat(value)}`
    }



    const minKmDistance = 1;

    const handleKmFilterChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setKmFilter([Math.min(newValue[0], kmFilter[1] - minKmDistance), kmFilter[1]]);
        } else {
            setKmFilter([kmFilter[0], Math.max(newValue[1], kmFilter[0] + minKmDistance)]);
        }
    };

    const valueKm = (value) => {
        return `${numberFormat(value)} km`;
    };



    return <>
        <Seo
            title="Nowy | Compra Autos Seminuevos de agencia"
            description="Encuentra los mejores autos seminuevos de agencia. Compra autos con garantía y a precios atractivos. Explora nuestro catálogo de vehículos disponibles."
            companyName="Nowy"
            type="website"
        />
        <div className="container mx-auto my-8 px-8 md:px-0 min-h-[100vh]">
            {!loading && (
                <div className="py-4 w-full lg:flex">
                    {isMobile ? (
                        <span className="cursor-pointer mr-8 mt-1" onClick={toggleFilters}>
                            <TuneIcon /> {shownFilters ? 'Ocultar' : 'Mostrar'} filtros
                        </span>) : (
                        <span className="cursor-pointer mr-8 mt-1" onClick={toggleFilters}>
                            <TuneIcon /> {shownFilters ? 'Mostrar' : 'Ocultar '} filtros
                        </span>


                    )}
                    {Object.keys(filterTags).map((key, index) => (<Chip key={`chip_filter_${index}`}
                        sx={{
                            marginRight: '10px',
                            '& .MuiChip-deleteIcon': {
                                color: 'white',
                            },
                        }}
                        variant="outlined"
                        label={filterTags[key]}
                        style={{ backgroundColor: 'black', color: 'white' }}
                        onDelete={() => {
                            deleteFilter(key)
                        }}
                    />))}

                    <div className="ml-auto sm:text-center">
                        {cars.length > 0 && (
                            <p className="text-gray-600">{cars.length} vehículos disponibles</p>
                        )}
                    </div>

                </div>
            )}

            <div className="flex flex-row">
                {(isMobile ? shownFilters : !shownFilters) && !loading && (
                    <div className="filters__content w-full z-50 py-8 md:py-0 md:w-[40%]  lg:w-[20%] h-full overflow-auto md:h-auto px-6 md:px-0 md:mr-8 fixed top-0 left-0 md:relative bg-white">
                        <div className="pb-4 flex md:hidden justify-between">
                            <h2>Filtros</h2>
                            <span onClick={closeFilters}>Cerrar</span>
                        </div>

                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Precio</b>
                            </div> */}

                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('prices')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center">
                                    <b>{filterTags.price || 'Precio'}</b>
                                    <span className="material-icons">{openFilters.prices ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                                </button>
                                {openFilters.prices && (
                                    <div className="text-center">
                                        <Box sx={{ width: '70%', margin: 'auto' }}>
                                            <Slider
                                                getAriaLabel={() => 'Minimum distance'}
                                                marks={marksPrice}
                                                value={priceFilter}
                                                min={minPriceFilter}
                                                max={maxPriceFilter}
                                                step={stepPriceFilter}
                                                onChange={handlePriceFilterChange}
                                                valueLabelDisplay="auto"
                                                getAriaValueText={valuePrice}
                                                valueLabelFormat={valuePrice}
                                                disableSwap
                                            />
                                        </Box>
                                        <Button variant="outlined" onClick={applyPriceFilter}>Aplicar precio</Button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Marca</b>
                            </div> */}
                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('brands')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center"
                                >
                                    <b>Marca</b>
                                    <span className="material-icons">{openFilters.brands ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                                </button>
                                {openFilters.brands && (
                                    <div >
                                        <TextField
                                            sx={{ width: '100%' }}
                                            variant="filled"
                                            onChange={handleBrandFilterChange}
                                            className="border-b"
                                        />
                                        <List
                                            sx={{ maxHeight: '300px', overflowY: 'auto', bgcolor: 'background.paper' }}
                                        >
                                            {brandsFilter.map((brand, index) => (
                                                <ListItem key={`brand-${index}`} disablePadding>
                                                    <ListItemButton
                                                        selected={filterTags.marca === brand.name}
                                                        onClick={() => selectBrand(brand)}
                                                    >
                                                        <ListItemText primary={brand.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                )}
                            </div>
                        </div>

                        {filterTags.marca && !!modelsOptions.length && (



                            <div className="relative pb-4 border-b border-slate-300">
                                {/* <div className="pb-3">
                                    <b>Modelo</b>
                                </div> */}
                                <div className="relative">
                                    <button
                                        onClick={() => toggleOpen('models')}
                                        className="w-full p-2  rounded bg-white text-left flex justify-between items-center"
                                    >
                                        <b>Modelo</b>
                                        <span className="material-icons">{openFilters.models ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                                    </button>

                                    {openFilters.models && (
                                        <div>
                                            <TextField sx={{ width: '100%' }} variant="filled" onChange={handleModelFilterChange} />


                                            <List className="w-full"
                                                sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                                            >
                                                {modelsFilter.map((model, index) => (
                                                    <ListItem key={`model-${index}`} disablePadding>
                                                        <ListItemButton selected={filterTags.modelo === model.name}
                                                            onClick={() => selectModel(model)}
                                                        >
                                                            <ListItemText primary={model.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Tipo de Vehículo</b>
                            </div> */}
                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('types')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center"
                                >
                                    <b>Tipo de vehículo</b>
                                    <span className="material-icons">{openFilters.types ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                                </button>
                                {openFilters.types && (
                                    <List className="w-full"
                                        sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                                    >
                                        {typeOptions.map((type, index) => (
                                            <ListItem key={`type-${index}`} disablePadding>
                                                <ListItemButton selected={filterTags.tipo === type.name}
                                                    onClick={() => selectType(type)}
                                                >
                                                    <ListItemText primary={type.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </div>
                        </div>

                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Año</b>
                            </div> */}

                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('years')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center"
                                >
                                    <b>Año</b>
                                    <span className="material-icons">{openFilters.years ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                                </button>


                                {openFilters.years && (
                                    <div className="flex flex-wrap items-stretch">
                                        {yearsOptions.map((year, index) => (
                                            <div className="mr-1 my-2" key={`year-${index}`}>
                                                <Chip
                                                    label={year}
                                                    variant={Number(filterTags['año']) === year ? 'filled' : 'outlined'}
                                                    onClick={() => selectYear(year)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Estado del Vehículo</b>
                            </div> */}
                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('states')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center">
                                    <b>Estado del vehículo</b>
                                    <span className="material-icons">{openFilters.states ? 'arrow_drop_up' : 'arrow_drop_down'}</span>

                                </button>
                                {openFilters.states && (
                                    <List className="w-full"
                                        sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                                    >
                                        {stateOptions.map((state, index) => (
                                            <ListItem key={`state-${index}`} disablePadding>
                                                <ListItemButton selected={filterTags.estado === state.name}
                                                    onClick={() => selectState(state)}
                                                >
                                                    <ListItemText primary={state.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </div>
                        </div>

                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Kilometraje</b>
                            </div> */}

                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('kms')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center">
                                    <b>Kilometraje</b>
                                    <span className="material-icons">{openFilters.kms ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                                </button>
                                {openFilters.kms && (
                                    <div className="text-center">
                                        <Box sx={{
                                            width: '70%',
                                            margin: 'auto',
                                            '@media (min-width: 1024px) and (max-width: 1024px)': {
                                                width: '61%',
                                            }
                                        }}>
                                            <Slider
                                                getAriaLabel={() => 'Minimum distance'}
                                                marks={marksKm}
                                                value={kmFilter}
                                                min={minKmFilter}
                                                max={maxKmFilter}
                                                step={stepKmFilter}
                                                onChange={handleKmFilterChange}
                                                valueLabelDisplay="auto"
                                                getAriaValueText={valueKm}
                                                valueLabelFormat={valueKm}
                                                disableSwap
                                            />
                                        </Box>
                                        <Button variant="outlined" onClick={applyKmFilter}>Aplicar kilometraje</Button>
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Transmisión</b>
                            </div> */}

                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('transmitions')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center">
                                    <b>Transmisión</b>
                                    <span className="material-icons">{openFilters.transmitions ? 'arrow_drop_up' : 'arrow_drop_down'}</span>

                                </button>
                                {openFilters.transmitions && (
                                    <List className="w-full"
                                        sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                                    >
                                        {transmitionOptions.map((transmition, index) => (
                                            <ListItem key={`transmition-${index}`} disablePadding>
                                                <ListItemButton selected={filterTags.transmision === transmition.name}
                                                    onClick={() => selectTransmition(transmition)}
                                                >
                                                    <ListItemText primary={transmition.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </div>
                        </div>


                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Color</b>
                            </div> */}
                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('colors')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center">
                                    <b>Color</b>
                                    <span className="material-icons">{openFilters.colors ? 'arrow_drop_up' : 'arrow_drop_down'}</span>

                                </button>
                                {openFilters.colors && (
                                    <List className="w-full"
                                        sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                                    >
                                        {colorOptions.map((colors, index) => (
                                            <ListItem key={`colors-${index}`} disablePadding>
                                                <ListItemButton selected={filterTags.color === colors.name}
                                                    onClick={() => selectColors(colors)}
                                                >
                                                    <ListItemText primary={colors.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </div>
                        </div>


                        <div className="relative pb-4 border-b border-slate-300">
                            {/* <div className="pb-3">
                                <b>Pasajeros</b>
                            </div> */}
                            <div className="relative">
                                <button
                                    onClick={() => toggleOpen('passengers')}
                                    className="w-full p-2  rounded bg-white text-left flex justify-between items-center">
                                    <b>Pasajeros</b>
                                    <span className="material-icons">{openFilters.passengers ? 'arrow_drop_up' : 'arrow_drop_down'}</span>

                                </button>
                                {openFilters.passengers && (
                                    <div className="flex flex-wrap items-stretch">
                                        {passengersOptions.map((passenger, index) => (
                                            <div className="mr-1 my-2" key={`year-${index}`}>
                                                <Chip
                                                    label={passenger}
                                                    variant={Number(filterTags['Pasajeros']) === passenger ? 'filled' : 'outlined'}
                                                    onClick={() => selectPassenger(passenger)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>



                    </div>
                )}



                <div className="w-4/5 grow md:px-0">
                    <Grid container spacing={2} sx={{ display: 'grid', gridTemplateColumns: ' repeat(auto-fill, minmax(320px, 1fr))' }}>
                        {loading && Array.from(new Array(8)).map((item, index) => (
                            <Grid item key={`loading-${index}`}>
                                <Box key={index} sx={{ marginRight: 0.5, mb: 2 }} className="w-full">
                                    <Skeleton variant="rectangular" height={250} />
                                    <h2 className="mt-2">
                                        <Skeleton variant="rectangular" height={20} />
                                    </h2>
                                </Box>
                            </Grid>
                        ))}

                        {!loading && cars.length === 0 && (<div className="text-center py-8">
                            <b>No se han podido encontrar opciones en función de sus criterios de búsqueda</b>
                        </div>)}

                        {!loading && (currentCars.map((car, index) => (
                            <Grid
                                key={`car-${index}`}
                                item
                                onClick={() => {
                                    ReactPixel.trackCustom('Contenido Seleccion de vehículo', car);
                                    goToCar(car)
                                }}
                            >
                                <CarCard car={car} />
                            </Grid>
                        ))
                        )}

                    </Grid>
                    {cars.length > 0 && (
                        <div className="flex justify-center mt-4">
                            <PaginationRounded
                                productPerpege={productPerpege}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalProduct={totalProduct}
                            />
                        </div>

                    )}
                </div>

            </div>

        </div>
    </>
}

// export component
export default CarsForSale
