// components
import React from 'react'
import {
    Box,
    Grid,
    Button
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
// slider images
import backgroundImg from '../../../assets/images/home/home-cambia-o-vende_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'


const backgroundImage = {
    image: backgroundImg,
    alt: 'home-cambia-o-vende_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
}




// component
const HomeBanner = React.memo(() => {
    return (
        <Box className="container-fluid py-[20px]"
            sx={{
                backgroundColor: '#00CE7C',
            }}
        >
            <Grid container
                spacing={2}
                alignItems="center"
                sx={{
                    height: {
                        lg: '500px',
                        xs: 'auto',
                    },
                }}
            >
                <Grid item
                    xs={12}
                    lg={6}
                    sx={{
                        display: 'flex',
                        justifyContent: {
                            xs: 'center',
                            lg: 'flex-end',
                        },
                    }}
                >
                    <Box sx={{
                        width: {
                            xs: '100%',
                            sm: '85%',
                            md: '85%',
                            lg: '400px',
                            xl: '500px',
                        },
                        marginRight: {
                            lg: '120px',
                        },
                        padding: '20px',
                    }}>
                        <h4 className='font-title text-4xl'>
                            Cambia o vende tu auto en 24 horas
                        </h4>
                        <h4 className='font-regular text-white pt-[20px] py-5'>
                            Agenda una inspección a domicilio o en nuestra sede más cercana.
                            En Nowy vendes tu auto al mejor precio del mercado, o te facilitamos el tomarlo a cuenta para que estrenes uno mejor.
                        </h4>
                        <RouterLink to='/vende-tu-auto'>
                            <Button
                                variant="contained"
                                className="w-48 rounded-full"
                                sx={{
                                    borderRadius: '20px',
                                    backgroundColor: "#000",
                                    '&:hover': {
                                        backgroundColor: '#000',
                                    },
                                }}
                            >
                                Vender tu Auto
                            </Button>
                        </RouterLink>
                    </Box>
                </Grid>
                <Grid item
                    xs={12}
                    lg={6}
                    sx={{
                        marginLeft: {
                            xs: '20px',
                            lg: 0,
                        },
                    }}
                >
                    <Box className='flex justify-end'>
                        <img
                            className="max-h-[350px] w-auto xl:max-h-[457px]"
                            src={backgroundImage.image}
                            alt={backgroundImage.alt}
                            loading='lazy'
                            width='auto'
                            height='350px'
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
});
// export
export default HomeBanner
