import { Route, Routes } from "react-router-dom"
import { PreguntasFrecuentes, PreguntasFrecuentesCategoria, PreguntasFrecuentesSingle } from "../pages"
import {  Grid } from "@mui/material"

export const PreguntasFrecuentesRoute = () => {

    return (
        <Grid component='section'  sx={{minHeight:'65dvh', margin: 'auto'}}>
            <Routes>
                <Route path="/" element={<PreguntasFrecuentes />} />
                <Route path="/:categoria" element={<PreguntasFrecuentesCategoria />} />
                <Route path="/:categoria/:pregunta" element={<PreguntasFrecuentesSingle />} />
            </Routes>

        </Grid>
    )
}
