import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import {  useParams } from 'react-router-dom';
import {  preguntaDislike, preguntaExists, preguntaLike } from '../../../services/preguntasFrecuentes';
import { Box, Grid, Typography, CircularProgress, Container, IconButton } from '@mui/material';
import parse from 'html-react-parser';
import { PreguntasBreadcrumbs } from '../components';
import { ThumbDown, ThumbDownOffAlt, ThumbUp, ThumbUpOffAlt } from '@mui/icons-material';

import  './single.scss'

export const PreguntasFrecuentesSingle = () => {



  const { pregunta, categoria } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [singleExist, setSingleExist] = useState(false);
  const [itemsBreadcrumbs, setItemsBreadcrumbs] = useState([])
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  // const [inCategoria, setInCategoria] = useState();

  const [preguntaObtenida, setPreguntaObtenida] = useState();



  const getPregunta = useCallback(async () => {
    const resp = await preguntaExists(pregunta);
    const categorias = resp[0]?._embedded['wp:term'][0];
    const categoriaExiste = categorias ? categorias.some(cat => cat.slug === categoria) : null;
    if (categoriaExiste) {
      setPreguntaObtenida(resp[0]);
      setItemsBreadcrumbs([
        { title: categorias[0].name, slug: `preguntas-frecuentes/${categorias[0].slug}` },
        { title: resp[0].title.rendered, slug: `preguntas-frecuentes/${categorias[0].slug}/${resp[0].slug}` },
      ]);
      setSingleExist(true);
    }
    setIsLoading(false);
  }, [pregunta, categoria]);

  const onVerifyLikes = useCallback(() => {
    const savedLikes = localStorage.getItem(`liked_post_${preguntaObtenida?.id}`);
    if (savedLikes) {
      savedLikes === 'like' ? setIsLiked(true) : setIsDisliked(true);
    }
  }, [preguntaObtenida?.id]);


  useEffect(() => {
    if (!preguntaObtenida) {
      getPregunta();
    }
    onVerifyLikes();
  }, [preguntaObtenida, getPregunta, onVerifyLikes]);


  const onHandleLike = async () => {
    setIsDisliked(false);
    setIsLiked(true);
    const resp = await preguntaLike(preguntaObtenida?.id);
    if (resp) {
      localStorage.setItem(`liked_post_${preguntaObtenida?.id}`, 'like');
    }
  }


  const onHandleDislike = async () => {
    setIsLiked(false);
    setIsDisliked(true);
    const resp = await preguntaDislike(preguntaObtenida?.id);
    if (resp) {
      localStorage.setItem(`liked_post_${preguntaObtenida?.id}`, 'dislike');
    }
  }



  if (isLoading) {
    return (
      <Box sx={{ minHeight: '50dvh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <CircularProgress color='success' />
      </Box>
    )
  }

  if (!singleExist) {
    return (
      <Box sx={{ minHeight: '50dvh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Poppins-Bold', fontSize: '44px' }}>Verifica la url, no se encontro información</Typography>
      </Box>
    )
  }

  return (
    <Container maxWidth='xl'>
      <PreguntasBreadcrumbs items={itemsBreadcrumbs} />
      <Container maxWidth="md">
        {/* Poner breadcrums aqui y remober el mt del contenedor del articulo */}
        <Grid container  className='content' sx={{ justifyContent: 'center', mt: 2, minHeight: '50vh', alignContent: 'start'}}>

          <Grid item xs={12} md={10} >
            <Typography variant='h1' sx={{ fontSize: '36px' }}>{preguntaObtenida.title.rendered}</Typography>
          </Grid>

          <Grid item xs={12} sx={{ mt: 5 }}>
            {parse(preguntaObtenida.content.rendered)}
          </Grid>

        </Grid>

        <Grid container sx={{ borderBottom: '1px solid #94a3b8', width: 'fit-content', marginTop: 10, marginBottom: 5 }}>
          <Grid item sx={{ marginRight: 20 }}>
            <Typography sx={{ fontSize: '23px', fontWeight: 'bold' }}>¿Te ayudo la información?</Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={onHandleLike}>
              {/* //TODO:switchear entre outline y filled like icon */}
              {isLiked ? <ThumbUp sx={{color:'#00ce7c'}}/> : <ThumbUpOffAlt />}
            </IconButton>
            <IconButton onClick={onHandleDislike}>
              {isDisliked ? <ThumbDown sx={{color:'#00ce7c'}}/> : <ThumbDownOffAlt />}
              {/* //TODO:switchear entre outline y filled dislike icon */}

            </IconButton>
          </Grid>

        </Grid>
      </Container>
    </Container>
  )
}
