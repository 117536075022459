import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { Box } from '@mui/material';
import aboutBackgound from '../../../assets/images/home/diseño-diagonales_agenda-cita_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp';

const LazyYouTube = lazy(() => import('react-youtube'));

const videoId = 'FqLxdIKOuQQ';

function HomeVehicles() {
    const [loadVideo, setLoadVideo] = useState(false);
    const videoSectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setLoadVideo(true);
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });

        const currentRef = videoSectionRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <Box
            className="container-fluid"
            sx={{
                padding: '20px',
                backgroundColor: '#000',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${aboutBackgound})`,
            }}
        >
            <Box className="container">
                <h3 className='font-title text-5xl text-brandPrimary text-center mb-[20px]'>
                    Quiénes somos
                </h3>
                <p className='font-text text-base text-white text-center px-[40px] mt-[20px]'>
                    Somos una agencia digital que ofrece servicios automotrices a través de una experiencia superior al actual mercado automotriz.
                    Brindamos un catálogo amplio de vehículos seminuevos certificados en seguridad y calidad, trabajando constantemente en
                    la mejora continua para ofrecer a todos nuestros clientes procesos sencillos, seguros y transparentes.
                </p>

                <Box
                    ref={videoSectionRef}
                    sx={{
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {loadVideo && (
                        <Suspense fallback={<div>Cargando...</div>}>
                            <Box sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex',
                                },
                            }}>
                                <LazyYouTube
                                    videoId={videoId}
                                    opts={{
                                        height: '390',
                                        width: '640',
                                        playerVars: {
                                            autoplay: 0,
                                            controls: 1,
                                        }
                                    }}
                                />
                            </Box>

                            <Box sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'flex',
                                    md: 'none',
                                },
                            }}>
                                <LazyYouTube
                                    videoId={videoId}
                                    opts={{
                                        height: '260',
                                        width: '426',
                                        playerVars: {
                                            autoplay: 0,
                                            controls: 1,
                                        }
                                    }}
                                />
                            </Box>

                            <Box sx={{
                                display: {
                                    xs: 'flex',
                                    sm: 'none',
                                },
                            }}>
                                <LazyYouTube
                                    videoId={videoId}
                                    opts={{
                                        height: '195',
                                        width: '321',
                                        playerVars: {
                                            autoplay: 0,
                                            controls: 1,
                                        }
                                    }}
                                />
                            </Box>
                        </Suspense>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default HomeVehicles;