import { Search, Send } from '@mui/icons-material'
import { Box, Grid, InputBase, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Link as RouterLink } from "react-router-dom";
import { useState } from 'react';

export const PreguntasList = ({ preguntas = [], cantidad = 6 }) => {

    const [pregutnasObtenidas, setPregutnasObtenidas] = useState(preguntas)

    const onSearchChange = ({target}) => {
        // console.log(target.value);
        const word = target.value;
        // console.log(word);
        if(word !== ''){
            const dataFilter = preguntas.filter((data) => data?.title?.rendered.toLowerCase().includes(word.toLowerCase()))
            setPregutnasObtenidas(dataFilter);
        }else{
            setPregutnasObtenidas(preguntas)
        }
    }
    
    return (
        <Grid container maxWidth='xl' sx={{ marginTop: 3, padding: {xs: 5, md:8}}}>
            <Grid container>
                <Grid item xs={12}>
                    {
                        cantidad < 8 ? (
                            <Grid item xs={12}><Typography sx={{ fontWeight: 'bold', fontSize: '1.5em' }}>Preguntas frecuentes</Typography></Grid>
                        ) : (
                            <Grid item xs={12} sm={6} sx={{ mb: 1, marginTop: 10 }}>
                                <Box sx={{ padding: 1, display: 'flex', alignItems: 'center', borderRadius: 1, border: '1px solid #94a3b8' }}>
                                    <InputBase placeholder='Buscar' sx={{ width: '100%' }} onChange={onSearchChange}/>
                                    <Search sx={{ mx: 1, p: '2px' }} />
                                </Box>
                            </Grid>

                        )
                    }
                </Grid>
                <Grid item xs={12} >
                    <List sx={{
                        display: 'grid',
                        gridTemplateRows: { sm: `repeat(${cantidad / 2}, 1fr)`, xs: `repeat(${cantidad}, 1fr)` },
                        gridTemplateColumns: { sm: 'repeat(2, 1fr)', xs: `1fr` },
                        gridAutoFlow: 'column',
                        // height:'270px',
                        columnGap: '5%',
                    }}>
                        {pregutnasObtenidas.length > 0 ? (pregutnasObtenidas.slice(0, cantidad).map((preg, index) => (
                            <Link key={index} underline="none" component={RouterLink} color='inherit' to={`/preguntas-frecuentes/${preg._embedded['wp:term'][0][0].slug}/${preg.slug}`}>
                                <ListItem sx={{ paddingX: 0 }}>
                                    <ListItemButton sx={{ padding: 0, borderBottom: '1px solid #94a3b8', height: '50px', overflow: 'hidden' }}>
                                        <ListItemText primary={preg?.title?.rendered} sx={{ height: '50px', "& span": { height: '50px', alignContent: 'center' } }} />
                                        <ListItemIcon sx={{ color: '#00ce7c', justifyContent: 'end', mr: '8px' }}><Send /></ListItemIcon>
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        ))):
                        (
                            <Typography>Pronto tendremos mas informacion</Typography>
                        )}


                    </List>
                </Grid>

            </Grid>
        </Grid>






    )
}
