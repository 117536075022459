import  { create }  from 'zustand';
import { persist } from "zustand/middleware";

export const useAuthStore = create(persist(
    (set) => ({
        status: '',  // 'checking', 'not-authenticated, 'authenticated'
        token: '',
        profile: null,
        isAuth: false,
        authChange:() => set((state) => ({
            status: 'checking',
            token: '',
            isAuth: false,
            profile: null,
        })),
        setToken: (token) => set((state) =>({
            status: 'authenticated',
            token,
            isAuth: true,
        })),
        setProfile: (profile) => set((state) =>({
            profile
        })),
        logout: () => set((state) => ({
            status: 'not-authenticated',
            token: '',
            isAuth: false,
            profile: null,
        }))
    }),{
        name: 'auth',
    }
));
